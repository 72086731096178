.enquiry-section {
    position: relative;
    background-image: url('../Components/Assets/bg9.jpeg');
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.enquiry-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.enquiry-overlay h1 {
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
}

.enquiry-form-section {
    background-color: #f0f0f0;
    padding: 50px 0;
}

.enquiry-form-container {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
}

h2 {
    text-align: center;
    color: var(--primary-color);
    font-size: 24px;
    margin-bottom: 20px;
}

.enquiry-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
    color: var(--text-color);
}

.enquiry-form input,
.enquiry-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: box-shadow 0.2s ease;
}

.enquiry-form input:focus,
.enquiry-form select:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px rgba(0, 71, 171, 0.5);
}

.enquiry-form button {
    width: 100%;
    padding: 12px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.enquiry-form select {
    appearance: none;
    background-color: white;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polygon points="0,0 20,0 10,10" fill="%230047AB" /></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding-right: 30px;
}

.enquiry-form select:hover {
    border-color: var(--primary-color);
}