.footer {
  background-color: var(--primary-color);
  color: #FFFFFF;
  padding: 20px 0;
  margin-top: 80px;
}

/* .footer-columns {
  display: flex;
  justify-content: space-between;
  padding: 20px;
} */

/* .footer-column {
  flex: 1;
  padding: 0 20px;
} */

.footer-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: #FDCB0A;
}

.footer-logo {
  width: 130px;
  margin-bottom: 15px;
}

.footer-address {
  margin: 5px 0;
  color: white;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin: 5px 0;
}

.footer-links a {
  color: #f9f8f3;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #ffffff;
}

.footer-bottom p {
  color: white;
}