* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

:root {
  --primary-color: #b52a2a;
  --secondary-color: #0047AB;
  --accent-color: #FDCB0A;
  --background-color: #FFFFFF;
  --text-color: #2B2B2B;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: transparent;
  /* Initially transparent */
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: none;
  transition: all 0.3s ease;
}

.header.scrolled {
  background-color: var(--primary-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo img {
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.nav ul {
  display: flex;
  gap: 30px;
  list-style: none;
}

.nav a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
}

.nav a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: var(--accent-color);
  transition: width 0.3s ease;
}

.nav a:hover::after {
  width: 100%;
}

.nav a:hover {
  color: var(--accent-color);
}

.header-button {
  margin-left: auto;
  padding: 10px 25px;
  background-color: var(--accent-color);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s;
}

.header-button:hover {
  background-color: #e6a806;
  transform: scale(1.05);
}

.menu-icon {
  display: none;
  cursor: pointer;
  z-index: 2000;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    background-color: rgb(56 57 59 / 95%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: right 0.4s ease;
  }

  .nav-open {
    right: 0;
  }

  .nav ul {
    flex-direction: column;
    gap: 25px;
  }

  .header-button {
    width: 100%;
    padding: 8px;
    font-size: 10px;
    /* margin-top: 20px; */
    text-align: center;
  }

  .menu-icon {
    display: block;
    margin-right: 30px;
    margin-left: -120px;
  }
  .logo img {
    width: 65px;
    height: 65px;
}
}

@media (max-width: 768px) {
  .nav ul li {
    opacity: 0;
    animation: slideIn 0.3s forwards;
    animation-delay: 0.2s;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}