@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.faculty-page-section {
    position: relative;
    background-image: url('../Components/Assets/bg9.jpeg');
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faculty-page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.faculty-page-overlay h1 {
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
}

.faculty-page {
    padding: 20px;
    text-align: center;
    background-color: #f0f0f0;
    font-family: 'Roboto', sans-serif;
}

.faculty-title {
    font-size: 2.5em;
    color: var(--primary-color);
    font-weight: bold;
    margin-bottom: 30px;
    position: relative;
}

.faculty-title::after {
    content: '';
    display: block;
    width: 150px;
    height: 4px;
    background-color: #000;
    margin: 10px auto;
    border-radius: 2px;
}

.faculty-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.faculty-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

.faculty-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.faculty-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
}

.faculty-name {
    font-size: 1.5em;
    color: #333;
    margin: 10px 0;
    font-weight: 700;
}

.faculty-position {
    font-size: 1.1em;
    color: #555;
    margin: 5px 0;
}

.faculty-school {
    font-size: 1em;
    color: #777;
    margin-top: 10px;
}