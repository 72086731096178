@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.booklist-section {
  position: relative;
  background-image: url('../Components/Assets/bg9.jpeg');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booklist-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.booklist-overlay h1 {
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
}

.booklist-page {
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
  font-family: 'Roboto', sans-serif;
}

.booklist-title {
  font-size: 2.5em;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 30px;
  position: relative;
}

.booklist-title::after {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  background-color: #000;
  margin: 10px auto;
  border-radius: 2px;
}

.booklist-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.book-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.book-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.book-class-name {
  font-size: 1.3em;
  color: #333;
  margin: 10px 0;
  font-weight: 700;
}

.view-details-button {
  padding: 10px 12px;
  margin-top: 10px;
  font-size: 0.9em;
  color: white;
  background-color: var(--accent-color);
  border: none;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-details-button:hover {
  background-color: var(--secondary-color);
}