.contact-section {
    position: relative;
    background-image: url('../Components/Assets/bg9.jpeg');
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-overlay h1 {
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
}

.contact-us-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.contact-details-row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.contact-info {
    flex: 1;
    min-width: 200px;
    text-align: center;
    margin: 10px;
}

.label {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 5px;
    color: #333;
}

.contact-map {
    width: 100%;
    max-width: 800px;
}
