.aboutus-section {
    position: relative;
    background-image: url('../Components/Assets/bg9.jpeg');
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutus-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutus-overlay h1 {
    color: #ffffff;
    font-size: 3rem;
    font-weight: bold;
}

.about-us {
    padding: 50px 0;
    background-color: #f9f9f9;
}

.about-us .about-title,
.about-us .section-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #b52a2a;
    margin-bottom: 15px;
}

.about-us .about-description {
    color: #2b2b2b;
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.about-page-img img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-page-img {
    text-align: center;
}

.aboutnext-section {
    padding: 50px 0;
}

.about-feature {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    height: 250px;
    /* Fixed height for uniform box size */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.about-feature:hover {
    transform: translateY(-5px);
}

.section-title {
    font-size: 1.2em;
    font-weight: bold;
    color: #b52a2a;
    margin-top: 10px;
}

.aboutnext-description {
    color: #2b2b2b;
    font-size: 0.95em;
    line-height: 1.5;
    margin-top: 10px;
}

.aboutus-next-section {
    padding: 20px 0;
    background-color: #f9f9f9;
}

.aboutus-next-section .about-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #b52a2a;
    margin-bottom: 15px;
}

.aboutus-next-section .about-description {
    color: #2b2b2b;
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.about-next-img img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-next-img {
    text-align: center;
}

.school-hours-section {
    padding: 20px 0;
    background-color: #f9f9f9;
}

.school-hours-section .section-title,
.school-hours-section .sub-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #b52a2a;
    margin-bottom: 15px;
}

.school-hours-section .sub-title {
    margin-top: 20px;
}

.school-hours-section p {
    color: #2b2b2b;
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 10px;
}

.note {
    font-style: italic;
    color: #666;
}

.school-hours-section .row {
    margin-bottom: 30px;
}

.uniform-section {
    padding: 50px 0;
    background-color: #f9f9f9;
}

.uniform-section .section-title,
.uniform-section .sub-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #b52a2a;
    margin-bottom: 15px;
}

.uniform-section p {
    color: #2b2b2b;
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 10px;
}

.uniform-section .row {
    margin-bottom: 30px;
}

.winter-uniform {
    margin-top: 30px;
}



@media (max-width: 768px) {
    .about-feature {
        margin: 15px 0;
        height: auto;
        /* Remove fixed height for smaller screens */
    }
}


@media (max-width: 576px) {
    .about-us {
        text-align: center;
    }

    .about-page-img img {
        margin-top: 20px;
    }
}