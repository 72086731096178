@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #b52a2a;
  --secondary-color: #0047AB;
  --accent-color: #FDCB0A;
  --background-color: #FFFFFF;
  --text-color: #2B2B2B;
}

a {
  text-decoration: none;
}

body {
  font-family: "Poppins", sans-serif;
}

/* Hero Section */
.hero {
  position: relative;
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.hero-slide {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 0;
  color: white;
  text-align: center;
  padding-top: 20%;
}

.cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.cta-buttons button {
  padding: 12px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.cta-buttons .btn-primary {
  background-color: #f7ab0a;
  color: white;
}

.cta-buttons .btn-primary:hover {
  background-color: #e69c09;
}

.cta-buttons .btn-secondary {
  background-color: transparent;
  border: 2px solid #f7ab0a;
  color: white;
}

.cta-buttons .btn-secondary:hover {
  background-color: #f7ab0a;
  color: white;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.hero-content {
  position: relative;
  z-index: 2;
  animation: fadeInScale 1s ease forwards;
}

.hero h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  margin-bottom: 20px;
  animation: slideInFromLeft 1s ease forwards;
  font-weight: 500;
  letter-spacing: 1.3px;
}

.hero p {
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  color: #FFFFFF;
  margin-bottom: 30px;
  animation: slideInFromLeft 1s ease forwards;
  animation-delay: 0.5s;
}

.hero-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255,
      255,
      255,
      0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.arrow:hover {
  background-color: rgba(247, 171, 10, 0.8);
  color: white;
}

.next {
  right: 20px;
}

.prev {
  left: 20px;
}


/* about us css start */
.container-xxl {
  padding: 80px 0;
}

.heading {
  font-size: 2.8rem;
  color: #b52a2a;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 30px;
  transition: color 0.3s ease;
}

.heading:hover {
  color: #0047AB;
}

.about-para {
  font-size: 1rem;
  color: var(--text-color);
  line-height: 1.8;
  margin-bottom: 1.5rem;
  text-align: justify;
}

.about-para .mb-4 {
  margin-bottom: 1.8rem;
  font-weight: 500;
}

.btn-primary {
  background-color: #b52a2a;
  border: none;
  color: white;
  font-size: 1rem;
  padding: 14px 35px;
  border-radius: 50px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-primary:hover {
  background-color: #0047AB;
  transform: translateY(-4px);
}

.about-img {
  text-align: center;
}

.about-img img {
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.about-img img:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.center-image {
  display: block;
  margin: 0 auto;
}


/* Achievment css start */
.achievements-section {
  background-color: var(--background-color);
  padding: 60px 0;
}

.achievement-card {
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
}

.achievement-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.achievement-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

h2 {
  font-size: 2.5em;
  color: var(--primary-color);
}

h3 {
  font-size: 1.5em;
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.year {
  display: block;
  font-weight: bold;
  color: var(--accent-color);
  margin-top: 10px;
}

/* our team css start */
.our-team-wrapper {
  width: 86%;
  margin: 0 auto;
}

.team-card {
  height: 450px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: black;
  border-radius: 1rem;
}

.our-team-heading {
  text-align: center;
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
}

.team-image-container {
  height: 14rem;
  background-color: #b52a2a;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-image {
  height: 11rem;
  width: 11rem;
  border-radius: 9999px;
}

.team-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* gap: 10px;  */
  padding: 1rem;
}

.team-name {
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
}

.team-school {
  font-size: 1.25rem;
  font-weight: 600;
}

.slick-slide>div {
  margin: 0 10px;
}


/* admission css start */
.info-section {
  background-color: var(--primary-color);
  padding: 50px 0;
  color: var(--background-color);
}

.admission-section-heading {
  font-size: 2.5rem;
  color: var(--background-color);
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

.heading-underline {
  width: 100px;
  height: 3px;
  background-color: var(--accent-color);
  margin: 0 auto 40px auto;
}

.info-section .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.info-box {
  background-color: var(--background-color);
  padding: 20px;
  /* border-radius: 5px; */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 30px;
  width: 90%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s forwards;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

.fade-in:nth-child(1) {
  animation-delay: 0.3s;
}

.fade-in:nth-child(2) {
  animation-delay: 0.6s;
}

.info-box h3 {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.info-box p {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 20px;
}

.info-btn {
  background-color: var(--accent-color);
  color: var(--primary-color);
  padding: 15px 30px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.info-btn:hover {
  background-color: var(--secondary-color);
  color: var(--background-color);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* /booklist css start */
.class-section {
  background-color: #f9f9f9;
  padding: 50px 0;
}

.section-heading {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 40px;
}

.class-box {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s;
  margin-bottom: 30px;
}

.class-box:hover {
  transform: scale(1.05);
}

.class-box h3 {
  font-size: 1rem;
  color: #333;
  font-weight: 600;
}

.class-box a {
  background-color: var(--accent-color);
  color: white;
  padding: 6px 10px;
  font-size: 14px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.class-box a:hover {
  background-color: var(--secondary-color);
}

/* gallery section css start */
.gallery-section {
  background-color: #f9f9f9;
  padding: 50px 0;
}

.Gallery-section-heading {
  font-size: 2.5em;
  color: var(--primary-color);
  /* margin-bottom: 30px; */
  text-align: center;
}

.gallery-heading-line {
  width: 140px;
  height: 2px;
  background-color: var(--text-color);
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 20px;
}


.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 0;
  margin: 0;
}

.gallery-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 0;
  transition: transform 0.3s;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.gallery-item:hover img {
  transform: scale(1.1);
}

/* calendar css start */
.calendar-wrapper {
  background-image: url('../Components/Assets/about.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px;
  min-height: 100vh;
}

.calendar {
  max-width: 1000px;
  margin: 40px auto;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f9;
}

.calendar-heading {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-top: 20px;
  color: #2B2B2B;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 12px 12px 0 0;
  position: relative;
}

.calendar-header h2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 26px;
  font-weight: 600;
  color: var(--background-color);
}

.button-group {
  display: flex;
  align-items: center;
}

.nav-btn,
.today-btn {
  background-color: #fff;
  color: var(--primary-color);
  border: none;
  padding: 8px 15px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.nav-btn:hover,
.today-btn:hover {
  background-color: #e9e9e9;
}

.view-toggle button {
  background-color: #fff;
  color: var(--primary-color);
  border: 1px solid #ccc;
  padding: 6px 12px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.view-toggle button.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.view-toggle button:hover {
  background-color: #f1f1f1;
}

/* Ensure the grid layout properly aligns for each view */
.calendar-grid {
  display: grid;
  gap: 10px;
  padding: 30px;
}

.calendar-grid.month {
  grid-template-columns: repeat(7, 1fr);
  /* Month view should have 7 columns (days of the week) */
}

.calendar-grid.week {
  grid-template-columns: repeat(7, 1fr);
  /* Week view should have 7 columns */
}

.calendar-grid.day {
  grid-template-columns: 1fr;
  /* Day view is single column */
}

.calendar-day {
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: background-color 0.3s, transform 0.3s;
}

.calendar-day:hover {
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.today {
  background-color: var(--accent-color);
  color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.event {
  background-color: var(--secondary-color);
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 0.9em;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  transition: background-color 0.3s;
}

.event:hover {
  background-color: var(--primary-color);
}

button {
  padding: 12px 25px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {

  .calendar-wrapper {
    padding: 20px;
  }

  .calendar {
    margin: 20px auto;
    padding: 20px;
  }

  .calendar-heading {
    font-size: 24px;
    margin-bottom: 15px;
    padding-top: 15px;
  }

  .calendar-header {
    padding: 10px 15px;
    flex-direction: column;
  }

  .calendar-header h2 {
    font-size: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 40px;
  }

  .button-group {
    margin-bottom: 30px;
  }

  .nav-btn,
  .today-btn,
  .view-toggle button {
    padding: 6px 10px;
    font-size: 14px;
    margin: 5px;
  }

  .calendar-grid {
    padding: 15px;
    gap: 5px;
  }

  .calendar-grid.month {
    grid-template-columns: repeat(3, 1fr);
  }

  .calendar-grid.week {
    grid-template-columns: repeat(1, 1fr);
  }

  .calendar-day {
    padding: 10px;
    font-size: 14px;
  }

  .event {
    font-size: 0.75em;
    padding: 3px 6px;
  }
}

@media (max-width: 480px) {
  .calendar-heading {
    font-size: 20px;
  }

  .calendar-header h2 {
    font-size: 18px;
  }

  .nav-btn,
  .today-btn,
  .view-toggle button {
    font-size: 12px;
    padding: 4px 8px;
  }

  .calendar-day {
    padding: 8px;
    font-size: 12px;
  }
}




/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .hero-slide {
    height: 40vh;

  }

  .hero h1 {
    font-size: 1.2rem;
    margin-bottom: 0px;
  }

  .hero-content {
    margin-top: 30px;
  }

  .container-xxl {
    padding: 30px 0;
  }

  .heading {
    font-size: 2rem;
  }

  .btn-primary {
    width: 100%;
    text-align: center;
  }

  p {
    font-size: 1rem;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

}

@media (max-width: 576px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media (max-width: 576px) {
  .achievement-image {
    height: 150px;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .info-box {
    padding: 15px;
    margin-top: 20px;
    width: 100%;
  }

  .section-heading {
    font-size: 2rem;
  }

  .info-box h3 {
    font-size: 1.5rem;
  }

  .info-box p {
    font-size: 1rem;
  }

  .info-btn {
    font-size: 1rem;
    padding: 12px 25px;
  }

  .achievement-card {
    padding: 15px;
  }

  .achievement-image {
    height: 180px;
  }
}


/* Responsive Styles */
@media (max-width: 992px) {
  .heading {
    font-size: 2.2rem;
  }

  .about-img img {
    width: 95%;
  }
}